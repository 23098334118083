<template>
  <div class="flow-count-container">
    <a-spin :spinning="spinning" >
      <div v-show="hasData" class="chart">
        <div class="chart" ref="chart"></div>
      </div>
      <div class="empty" v-show="!hasData"></div>
    </a-spin>
  </div>
</template>

<script>
import { trafficChart, trafficChartForSadmin } from '@/apiForManage/dashboard'
import option from './option'
import { mapState } from 'vuex'
import * as echarts from 'echarts'
import { numFormat } from '@/utils/dealNumber'
import { typeList } from './list'

export default {
  props: {
    query: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      option: {},
      myEcharts: undefined,
      spinning: false,
      hasData: true,
      typeList
    }
  },
  computed: {
    ...mapState({
      moneyType: (state) => +state.user.cur === 1 ? '$' : '¥',
      role: (state) => state.user.roles[0]
    }),
    isSAdmin () {
      return this.role === 'SUPER_ADMIN'
    }
  },
  created () {
    // parse返回Object类型, 对应给定JSON文本的对象/值。
    this.option = JSON.parse(JSON.stringify(option))
  },
  mounted () {
    this.myEcharts = echarts.init(this.$refs.chart, null, { render: 'svg' })
    window.addEventListener('resize', this.getSize)
  },
  destroyed () {
    this.myEcharts.dispose()
    window.removeEventListener('resize', this.getSize)
  },
  watch: {
    query: {
      handler (val, oldVal) {
        this.$nextTick(() => {
          this.getChartData()
        })
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    getSize () {
      // 判断是否是created时调用
      if (this.myEcharts) {
        setTimeout(() => {
          this.myEcharts.resize()
        }, 100)
      }
    },
    initChart () {
      this.myEcharts.setOption(this.option)
    },
    async getChartData () {
      this.spinning = true
      let resp
      if (this.isSAdmin) {
        resp = await trafficChartForSadmin(this.query)
      } else {
        resp = await trafficChart(this.query)
      }
      console.log(resp)
      this.list = resp.data.list
      const list = resp.data.list
      this.hasData = !!list.length
      const id = this.query.code
      const accountNameList = list.map((item) => item.accountName)
      const platNameList = list.map((item) => item.publishName) || []
      const companyList = list.map((item) => item[id]) || []
      this.option.xAxis.data = this.isSAdmin ? accountNameList : platNameList
      this.option.series[0].data = companyList || []
      this.option.series[0].name = id
      const name = this.typeList.find((item) => item.id === id).name
      this.option.tooltip.formatter = (params) => {
        let value = params.value
        if (params.seriesName === 'request') {
          value = numFormat(params.value, 3, '')
        } else if (params.seriesName === 'ecpm' || params.seriesName === 'profit') {
          value = this.moneyType + numFormat(params.value, 3, '')
        } else {
          value = value + '%'
        }
        return (
          params.marker + params.name +
          '<br/>&nbsp;&nbsp;&nbsp;' + `${ name }` + '：' + value
        )
      }
      this.option.series[0].itemStyle = {
        borderWidth: 2,
        color: (params) => {
          const colorList = [
            '#6DABE6',
            '#FF8A8A',
            '#FFAF65',
            '#64C8BC',
            '#7161C5',
            '#FFC5C5'
          ]
          return colorList[params.dataIndex]
        }
      }
      this.spinning = false
      this.initChart()
    }
  }
}
</script>

<style lang="less" scoped>
.flow-count-container {
  height: 100%;
  position: relative;
  .empty{
    width: 100%;
    height: 100%;
    background-image: url('/images/nodata_margex.png');
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center;
  }
  ::v-deep {
    .ant-spin-nested-loading {
      position: relative;
      height: 100%;
      .ant-spin-container {
        width: 100%;
        height: 100%;
        .chart {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
