export default {
  title: {
    show: false
  },
  tooltip: {
    trigger: 'item',
    transitionDuration: 0,
    appendToBody: true
  },
  grid: {
    left: '3%',
    top: '12%',
    right: '3%',
    bottom: '5%',
    containLabel: true
  },
  yAxis: {
    type: 'value',
    boundaryGap: [0, 0.01],
    show: true,
    minInterval: 1,
    axisTick: {
      show: false
    },
    splitLine: {
      show: true
    },
    axisLabel: {
      show: true,
      interval: 0,
      formatter (value, index) {
      }
    },
    splitNumber: 0
  },
  xAxis: {
    type: 'category',
    data: ['Brazil', 'Indonesia', 'USA', 'India', 'China', 'lll'],
    show: false,
    axisTick: {
      show: false
    },
    splitLine: {
      show: false
    },
    axisLabel: {
      show: true,
      interval: 0,
      fontSize: '12px'
    },
    splitNumber: 0
  },
  series: [{
    name: '数',
    xAxisIndex: 0,
    type: 'bar',
    data: [1, 2, 3, 4, 5, 9],
    itemStyle: {
      color: 'rgba(183, 105, 247, 1)'
    },
    barWidth: '50%',
    label: {
      show: false,
      position: 'top',
      color: '#8d8d8d'
    }
  }]
}
