/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-07-08 10:55:35
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-10-10 15:02:50
 * @FilePath: /mediatom-web/src/apiForManage/dashboard.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

const dashboardApi = {
  // 消耗汇总信息
  summaryData: '/dashboard/summary_data',
  // 预算消耗
  platData: '/dashboard/plat_data',
  // 数据趋势
  dashboardChart: '/dashboard/trend',
  // 流量排行
  trafficChart: '/dashboard/traffic_data',
  // 今日数据
  todayData: '/dashboard/today_data',
  // 流量数据趋势
  trafficTrendLine: '/dashboard/trafficTrend',
  trafficChartForSadmin: '/dashboard/Sadmin_traffic_data'
}

export function summaryData (data) {
  return request({
    url: dashboardApi.summaryData,
    method: 'post',
    data: data
  })
}

export function platData (data) {
  return request({
    url: dashboardApi.platData,
    method: 'post',
    data: data
  })
}

export function dashboardChart (data) {
  return request({
    url: dashboardApi.dashboardChart,
    method: 'post',
    data: data
  })
}

export function trafficChart (data) {
  return request({
    url: dashboardApi.trafficChart,
    method: 'post',
    data: data
  })
}

export function trafficChartForSadmin (data) {
  return request({
    url: dashboardApi.trafficChartForSadmin,
    method: 'post',
    data: data
  })
}

export function todayData (data) {
  return request({
    url: dashboardApi.todayData,
    method: 'post',
    data: data
  })
}

export function trafficTrendLine (data) {
  return request({
    url: dashboardApi.trafficTrendLine,
    method: 'post',
    data: data
  })
}
